<template>
  <Container class="flex">
    <div v-if="loading" class="qr">
      <p>Получаем данные от сервиса оплаты</p>
    </div>
    <div v-else-if="!res" class="qr">
      <p>мы не нашли такого заказа</p>
    </div>
    <div v-else-if="!res.paid" class="qr">
      <p>Сервис {{res.metadata.service}} не оплачен. Попробуйте еще раз.</p>
      <a :href="res.confirmation.confirmation_url" class="repay">оплатить</a>
    </div>

    <div v-else class="qr">
      <h2>Сервис {{res.metadata.service}} оплачен</h2>
      <p>
        {{ res.metadata.name }}, покажите QR код сотруднику или поднесите к
        считывателю
      </p>
      <div class="q">
        <QR
          v-if="res"
          bg-color="#fff6d7"
          :size="190"
          color="#2e0b25"
          :text="
            'https://service.veresk.club/check?id=' + res.metadata.orderID
          " />
      </div>
    </div>
  </Container>
</template>

<script>
import QR from 'vue-qrcode-component'
import Container from '../components/Container.vue'

export default {
  name: 'Pass',
  components: { Container, QR },
  data() {
    return {
      id: '',
      loading: true,
      res: '',
      baseUrl: process.env.VUE_APP_ENV_API
    }
  },
  methods: {
    async checkOrderPayment(id) {
      console.log('start Check')
      this.loading = true
      const result = await fetch(this.baseUrl + '/service/pay/status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ orderID: id })
      })
      // const data = await result.json()
      this.res = await result.json()
      console.log(this.res)
      // this.currentOrder.payment.cardInfo = data.payment_method
      // this.currentOrder.payment.status = data.status
      // this.currentOrder.payment.paid = data.status === 'succeeded'
      this.loading = false

      // this.currentOrder.payment.paid
      //   ? this.messages.push({
      //       msg: 'Мы получили оплату за ваш заказ'
      //     })
      //   : this.messages.push({
      //       msg: 'К сожалению оплата не прошла'
      //     })
    }
  },
  mounted() {
    // this.id = this.$route.query.id
    this.checkOrderPayment(this.$route.query.id)
  }
}
</script>

<style scoped>
.qr {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  background: #fff6d7;
  border-radius: 1rem;
  margin: auto;
}
h2 {
  margin: 0;
  text-transform: uppercase;
}
p {
  margin-bottom: 4rem;
  font-size: 1.5em;

  max-width: 256px;
  box-sizing: border-box;
}
.q canvas {
  width: 100%;
  /* Styles for the canvas */
}
.q img {
  width: 100%;
  /* Styles for the image */
}
.flex{
  display: flex;
  align-items: center;
  height: 100vh;
}
a.repay {
  padding: 1rem 2rem;
  background: #dd64ef;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  font-size: 2em;
  border-radius: 0.5rem;
  color: #ffffff;
}
</style>
