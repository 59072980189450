<template>
  <router-view />
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 0;
}
body {
  font-size: 10px;
  margin: 0;
  padding: 0;
  background: #dba4cc;
}
a {
  color: unset;
  text-decoration: none;
}
</style>
