<template>
  <Container
    class="screen"
    :class="{ paid: res.paid, unpaid: (!res && !loading) || !res.paid }">
    <div v-if="loading" class="qr">
      <p>Получаем данные от сервиса оплаты</p>
    </div>
    <div v-else-if="!res" class="qr">
      <h2>Заказ не найден</h2>
    </div>
    <div v-else-if="closeDialog" class="qr">
      <div v-if="close">
        <h2>Заказ закрыт</h2>
      </div>
      <div v-else>
        <h2>Закрываем заказ</h2>
        <p>Если вы отдали еду или прокатное оборудование вы можете закрыть заказ</p>
        <button @click="closeOrder()" class="paid">Закрыть</button>
        <button @click="closeDialog = false" class="unpaid">Отмена</button>
      </div>
    </div>
    <div v-else class="qr">
      <h2 v-if="res.paid">Оплачено</h2>
      <h2 v-else>Не оплачено</h2>
      <p><small>Имя:</small> {{ res.metadata.name }}</p>
      <p><small>Тел:</small> {{ res.metadata.phone }}</p>
      <p><small>Сервис:</small> {{ res.metadata.service }}</p>
      <ul v-if="res.food">
        <li v-for="(item, i) in res.food" :key="i">
          {{ item.name }} x {{ item.num }}шт.
        </li>
      </ul>
      <p>
        <small>Сумма:</small> {{ res.amount.value }}
        <small>{{ res.amount.currency }}</small>
      </p>
      <button @click="closeDialog = true"  class="unpaid">Закрыть заказ</button>
    </div>
  </Container>
</template>

<script>
import Container from '../components/Container.vue'

export default {
  name: 'Check',
  components: { Container },
  data() {
    return {
      id: '',
      loading: true,
      res: '',
      baseUrl: process.env.VUE_APP_ENV_API,
      closeDialog:false,
      close: false
    }
  },
  methods: {
    async checkOrderPayment(id) {
      console.log('start Check')
      this.loading = true
      const result = await fetch(this.baseUrl + '/service/pay/status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ orderID: id })
      })
      // const data = await result.json()
      this.res = await result.json()
      console.log(this.res)

      this.loading = false
    },
    async closeOrder() {
      const id = this.$route.query.id
      console.log('Closing order', id)
      this.loading = true
      const result = await fetch(this.baseUrl + '/service/close', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ orderID: id })
      })
      // const data = await result.json()
      const res = await result.json()
      this.close = res.status === 'ok' ? true : false

      console.log(this.close)

      this.loading = false
    }
  },

  mounted() {
    // this.id = this.$route.query.id
    this.checkOrderPayment(this.$route.query.id)
  }
}
</script>

<style scoped>
.qr {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.5em;
  padding: 1rem;
  background: #fff6d7;
  border-radius: 1rem;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
}
p {
  margin: 0 0 0.5rem 0;
}
p:last-child {
  margin: 0;
}
h2 {
  font-weight: bold;
  text-transform: uppercase;
}
.screen {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paid {
  background: green;
}
.unpaid {
  background: darkred;
}
small {
  font-size: 0.5rem;
  text-transform: uppercase;
}
button {
  appearance: none;
  inset: unset;
  border: none;
  padding: 1rem 2rem;
  border-radius: 0.4rem;
  font-size: .8rem;
  text-transform: uppercase;
  margin: .5rem ;
  color: white;
}
</style>
