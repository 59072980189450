<template>
  <Container>
    <div class="header">
      <router-link to="/"><div class="back">&larr;</div></router-link>
      <h1>{{ title }}</h1>
    </div>
    <div class="counter">
      <img :src="require(`@/assets/${type}.png`)" :alt="type" />
      <div class="hud">
        <div class="increase" @click="increase()">+</div>
        <input type="text" v-model="num" />
        <div class="decrease" @click="decrease()">-</div>
      </div>
    </div>
    <p class="msg">
      стоимость <strong>одного</strong> часа аренды - <strong>{{ price * num }}</strong
      ><small> руб.</small>
    </p>
    <div class="fields">
      <label>Ваше имя:</label>
      <input type="text" v-model="name" placeholder="Имя" />
      <label>Номер телефона: +79115552266</label>
      <input type="text" v-model="phone" placeholder="Телефон" />
      <Button
        :value="num * price"
        :name="name"
        :phone="phone"
        :service="title"
        :validation="valid" />
    </div>
  </Container>
</template>

<script>
import Button from './Button.vue'
import Container from './Container.vue'
export default {
  name: 'Service',
  props: ['price', 'type'],
  components: { Container, Button },
  data() {
    return {
      name: '',
      phone: '+7',
      num: 1
    }
  },
  computed: {
    title() {
      return this.type === 'skates'
        ? 'Прокат Коньков'
        : this.type === 'tube'
        ? 'Прокат Ватрушки'
        : this.type === 'stick'
        ? 'Прокат Клюшки'
        : this.type === 'sleigh' && 'Прокат Снегоката'
    },
    valid() {
      return !!this.name && !!this.phone && this.phone.length >= 11
    }
  },
  methods: {
    increase() {
      this.num >= 10 ? 10 : this.num++
    },
    decrease() {
      this.num <= 1 ? 1 : this.num--
    }
  }
}
</script>

<style scoped>
p.msg {
  font-size: 1rem;
  margin-top: 0;
}
.fields {
  display: grid;
  gap: 0.5rem;
}
.counter {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
}
.increase,
.decrease {
  background: white;
  padding: 0.2rem;
  font-size: 1.3rem;
}
.increase {
  border-radius: 0.5rem 0.5rem 0 0;
}
.decrease {
  border-radius: 0 0 0.5rem 0.5rem;
}
input {
  appearance: none;
  -webkit-appearance: none;
  background: rgba(255, 255, 255, 0.16);
  color: black;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 0.5rem;
  padding: 0.8rem 1.2rem;
  font-size: 1.1rem;
  width: 100%;
}
input::placeholder {
  color: rgba(255, 255, 255, 0.72);
  font-size: 1.1rem;
}
.hud {
  width: 4rem;
  display: flex;
  flex-direction: column;
}
.hud input {
  text-align: center;
  border-radius: 0;
  margin: 0;
  outline: 0;
  border-top: none;
  border-bottom: none;
  padding: 0.3rem 0;
}
h1 {
  background: rgba(255, 255, 255, 0.36);
  margin: 0;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  width: 100%;
}
.header {
  display: flex;
  gap: 1rem;
}
.back {
  background: rgba(255, 255, 255, 0.16);
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  line-height: 1;
  font-size: 1rem;
  font-weight: bolder;
}
img {
  width: 45%;
  margin: 0;
}
label {
  font-size: 0.7rem;
  text-align: left;
  text-transform: uppercase;
  padding-left: 1rem;
}
</style>
