<template>
  <a
    href="#"
    :class="{ invalid: !validation }"
    @click.prevent="validation && payOrder()"
    >Оплатить</a
  >
</template>

<script>
export default {
  name: 'Button',
  props: ['value', 'name', 'phone', 'service', 'validation', 'food'],
  data() {
    return {
      baseUrl: process.env.VUE_APP_ENV_API
    }
  },
  methods: {
    async payOrder() {
      //* Делаем запрос в наш API для создания платежа в YKassa
      const result = await fetch(this.baseUrl + '/service/pay', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          value: this.value,
          name: this.name,
          phone: this.phone,
          service: this.service,
          food: this.food ? this.food : false,
          host: window.location.origin
        })
      })
      const data = await result.json()
      //* Сохраняем данные в массив заказов юзера в LocalStorage
      //* Переходим по ссылке для оплаты от YKassa
      window.location.href = data.confirmation
    }
  }
}
</script>

<style scoped>
a {
  padding: 1rem 2rem;
  background: yellow;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  font-size: 2em;
  border-radius: 0.5rem;
}
a.invalid {
  opacity: 0.4;
}
</style>
