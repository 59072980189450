<template>
  <Container>
    <div class="header">
      <router-link to="/"><div class="back">&larr;</div></router-link>
      <h1>{{ title }}</h1>
    </div>
    <div class="counter">
      <div class="info">
        <img :src="require(`@/assets/ribs.png`)" alt="Ребра" />
      </div>
      <p>{{ food.ribs.price }}<small>руб.</small> x</p>
      <div class="hud">
        <div class="increase" @click="increase('ribs')">+</div>
        <input type="text" v-model="food.ribs.num" />
        <div class="decrease" @click="decrease('ribs')">-</div>
      </div>
    </div>
    <div class="counter">
      <div class="info">
        <img :src="require(`@/assets/wings.png`)" alt="Крылья" />
      </div>
      <p>{{ food.wings.price }}<small>руб.</small> x</p>
      <div class="hud">
        <div class="increase" @click="increase('wings')">+</div>
        <input type="text" v-model="food.wings.num" />
        <div class="decrease" @click="decrease('wings')">-</div>
      </div>
    </div>
    <p class="msg">
      Ваш заказ
      <strong>{{ total }}</strong
      ><small> руб.</small>
    </p>
    <div class="fields">
      <label>Ваше имя:</label>
      <input type="text" v-model="name" placeholder="Имя" />
      <label>Номер телефона: +79115552266</label>
      <input type="text" v-model="phone" placeholder="Телефон" />
      <Button
        :value="total"
        :name="name"
        :phone="phone"
        :service="title"
        :food="food"
        :validation="valid" />
    </div>
  </Container>
</template>

<script>
import Button from './Button.vue'
import Container from './Container.vue'
export default {
  name: 'Food',
  components: { Container, Button },
  data() {
    return {
      name: '',
      phone: '+7',
      food: {
        wings: { num: 0, price: 300, name: 'Крылья' },
        ribs: { num: 0, price: 480, name: 'Ребра' }
      }
    }
  },
  computed: {
    title() {
      return 'Ребра и Крылья'
    },
    valid() {
      return (
        !!this.name && !!this.phone && this.phone.length >= 11 && !!this.total
      )
    },
    total() {
      return (
        this.food.ribs.price * this.food.ribs.num +
        this.food.wings.price * this.food.wings.num
      )
    }
  },
  methods: {
    increase(type) {
      this.food[type].num >= 20 ? 20 : this.food[type].num++
    },
    decrease(type) {
      this.food[type].num <= 0 ? 0 : this.food[type].num--
    }
  }
}
</script>

<style scoped>
p.msg {
  font-size: 1rem;
  margin-top: 0;
}
.fields {
  display: grid;
  gap: 0.5rem;
}
.counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
}
.counter .info {
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}
.counter p {
  display: inline-block;
  font-size: 1.2rem;
}
small {
  font-size: 0.5rem;
}
.increase,
.decrease {
  background: white;
  padding: 0.2rem;
  font-size: 1.3rem;
}
.increase {
  border-radius: 0.5rem 0.5rem 0 0;
}
.decrease {
  border-radius: 0 0 0.5rem 0.5rem;
}
input {
  appearance: none;
  -webkit-appearance: none;
  background: rgba(255, 255, 255, 0.16);
  color: black;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 0.5rem;
  padding: 0.8rem 1.2rem;
  font-size: 1.1rem;
  width: 100%;
}
input::placeholder {
  color: rgba(255, 255, 255, 0.72);
  font-size: 1.1rem;
}
.hud {
  width: 4rem;
  display: flex;
  flex-direction: column;
}
.hud input {
  text-align: center;
  border-radius: 0;
  margin: 0;
  outline: 0;
  border-top: none;
  border-bottom: none;
  padding: 0.3rem 0;
}
h1 {
  background: rgba(255, 255, 255, 0.36);
  margin: 0;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  width: 100%;
}
.header {
  display: flex;
  gap: 1rem;
}
.back {
  background: rgba(255, 255, 255, 0.16);
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  line-height: 1;
  font-size: 1rem;
  font-weight: bolder;
}
img {
  width: 100%;
  margin: 0;
}
label {
  font-size: 0.7rem;
  text-align: left;
  text-transform: uppercase;
  padding-left: 1rem;
}
</style>
