import { createRouter, createWebHistory } from 'vue-router'
// import Test from '@/views/Test'
import Main from '@/views/Main'
import Pass from '@/views/Pass'
import Check from '@/views/Check'
import Skates from '@/views/Skates'
import Tube from '@/views/Tube'
import Sleigh from '@/views/Sleigh'
import Stick from '@/views/Stick'
import Food from '@/components/Food'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Main
  },
  {
    path: '/pass',
    name: 'Pass',
    component: Pass
  },
  {
    path: '/check',
    name: 'Check',
    component: Check
  },
  {
    path: '/skates',
    name: 'Skates',
    component: Skates
  },
  {
    path: '/tube',
    name: 'Tube',
    component: Tube
  },
  {
    path: '/sleigh',
    name: 'Sleigh',
    component: Sleigh
  },
  {
    path: '/stick',
    name: 'Stick',
    component: Stick
  },
  {
    path: '/food',
    name: 'Food',
    component: Food
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
