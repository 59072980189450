<template>
  <Container>
    <h1>Экопарк Вереск Сервисы</h1>
    <p class="note">Выберете сервис для оплаты аренды:</p>
    <div class="services">
      <router-link to="/skates">
        <div class="cell">
          <img :src="require('@/assets/skates.png')" alt="" />
          <p>Коньки</p>
          <p class="price">100 <small>руб/час</small></p>
        </div>
      </router-link>
      <router-link to="/tube">
        <div class="cell">
          <img :src="require('@/assets/tube.png')" alt="" />
          <p>Ватрушка</p>
          <p class="price">100 <small>руб/час</small></p>
        </div>
      </router-link>
      <router-link to="/sleigh">
        <div class="cell">
          <img :src="require('@/assets/sleigh.png')" alt="" />
          <p>Снегокат</p>
          <p class="price">100 <small>руб/час</small></p>
        </div>
      </router-link>
      <router-link to="/stick">
        <div class="cell">
          <img :src="require('@/assets/stick.png')" alt="" />
          <p>Клюшка</p>
          <p class="price">100 <small>руб/час</small></p>
        </div>
      </router-link>
      <router-link to="/food">
        <div class="cell">
          <img :src="require('@/assets/food.png')" alt="" />
          <p>Еда</p>
          <p class="price">Ребра и Крылья</p>
        </div>
      </router-link>
    </div>
  </Container>
</template>

<script>
import Container from '@/components/Container.vue'
export default {
  name: 'Main',
  components: { Container }
}
</script>

<style scoped>
img {
  width: 60%;
  box-sizing: border-box;
}
.note {
  font-size: 1rem;
}
.services {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  place-items: center;
}
.cell p {
  font-size: 1rem;
  margin: 0 0 0.5rem 0;
  line-height: 1.1;
}
.cell .price {
  font-size: 0.8rem;
}
.cell .price small {
  font-size: 0.8em;
}
.cell {
  border: 1px solid #ffffff57;
  border-radius: 0.5rem;
  padding-top: 0.7rem;
}
h1 {
  background: rgba(255, 255, 255, 0.36);
  margin: 0;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
}
</style>
