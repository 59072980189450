<template>
  <Service :price="100" :type="'stick'" />
</template>

<script>
import Service from '../components/Service.vue'
export default {
  name: 'Stick',
  components: { Service }
}
</script>

<style></style>
