<template>
  <div class="container"><slot /></div>
</template>

<script>
export default {
  name: "Container",
};
</script>

<style scoped>
.container {
    padding: 2rem;
    text-align: center;
    box-sizing: border-box;
    max-width: 45rem;
}
</style>
